<template>
  <div>
    <b-card>
      <b-nav
        class="w-100"
        fill
        pills
      >
        <b-nav-item
          :active="tab === 'programs'"
          @click="tab = 'programs'"
        >
          Program
        </b-nav-item>
        <b-nav-item
          :active="tab === 'competitions'"
          @click="tab = 'competitions'"
        >
          Competition
        </b-nav-item>
        <b-nav-item
          :active="tab === 'events'"
          @click="tab = 'events'"
        >
          Event
        </b-nav-item>
      </b-nav>
      <hr>
      <div class="custom-search d-flex justify-content-between align-items-center">
        <div>
          <b-form-group
            class="form-inline"
            label="Search"
            label-size="sm"
          >
            <b-form-input
              v-model="searchTerm"
              class="d-inline-block mr-1"
              placeholder="Search Competitions"
              type="text"
            />
          </b-form-group>
          <b-form-radio-group
            v-model="invitationFilter"
            :options="[
              { text: 'All', value: null },
              { text: 'Accepted', value: 'accepted' },
              { text: 'Rejected', value: 'rejected' },
            ]"
            button-variant="light"
            buttons
            size="sm"
          />
        </div>
        <b-form-group>
          <b-form-radio-group
            id="btn-radios-1"
            v-model="view"
            :options="[
              { text: 'Grid', value: 'grid' },
              { text: 'Table', value: 'table' },
            ]"
            button-variant="light"
            buttons
            size="sm"
          />
        </b-form-group>
      </div>
      <b-overlay
        :show="$apollo.loading || mutationLoading"
        spinner-variant="primary"
      >
        <b-card
          v-if="filteredInvitations.length < 1"
          class="text-center"
        >
          You don't have any pending invites!
        </b-card>
        <section
          v-if="view === 'grid'"
          class="ecommerce-application"
        >
          <div class="grid-view">
            <opportunities-card
              v-for="(program, i) in filteredInvitations"
              :key="i"
              :opportunities-type="tab"
              :program="program"
              :show-program-details="showProgramDetails"
              :show-respond-modal="showRespondModal"
              :tab="'invitation'"
            />
          </div>
        </section>
        <section
          v-else-if="view === 'table' && filteredInvitations.length !== 0"
          class="table-view mt-1"
        >
          <opportunities-table
            :filtered-programs="filteredInvitations"
            :opportunities-type="tab"
            :search-term="searchTerm"
            :show-program-details="showProgramDetails"
            :show-respond-modal="showRespondModal"
            :tab="'invitation'"
          />
        </section>
        <b-modal
          id="respond-modal"
          cancel-title="Reject"
          ok-title="Accept"
          title="Respond to Invitation"
          @cancel="updateResponse(false)"
          @ok="updateResponse(true)"
        >
          <span
            v-if="selectedIndex !== null"
          >
            Would you like to join <strong class="text-primary">{{
              invitations[selectedIndex].title
            }}</strong> as a <strong
              class="text-primary"
            >{{ invitations[selectedIndex].users_associationtables[0].role }}</strong>?
          </span>
        </b-modal>
      </b-overlay>
    </b-card>
    <opportunities-details-modal
      :modal-details="modalDetails"
    />
  </div>
</template>
<script>
import {
  BCard, BFormGroup, BFormInput, BFormRadioGroup, BModal, BNav, BNavItem, BOverlay,
} from 'bootstrap-vue'
import gql from 'graphql-tag'
import { getUserData } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import OpportunitiesCard from '@/views/mentor-portal/opportunities/components/OpportunitiesCard.vue'
import OpportunitiesDetailsModal from '@/views/mentor-portal/opportunities/components/OpportunitiesDetailsModal.vue'
import OpportunitiesTable from '@/views/mentor-portal/opportunities/components/OpportunitiesTable.vue'

export default {
  components: {
    BFormRadioGroup,
    OpportunitiesTable,
    OpportunitiesCard,
    OpportunitiesDetailsModal,
    BFormInput,
    BFormGroup,
    BNav,
    BNavItem,
    BCard,
    BOverlay,
    BModal,
  },
  data() {
    return {
      modalDetails: null,
      selectedIndex: null,
      searchTerm: '',
      tab: 'programs',
      invitations: [],
      userID: getUserData().id,
      mutationLoading: false,
      invitationFilter: null,
      view: 'grid',
    }
  },
  computed: {
    filteredInvitations() {
      let list = this.invitations
      if (this.searchTerm.length > 0) {
        list = list.filter(program => program.title.toLowerCase()
          .includes(this.searchTerm.toLowerCase()))
      }
      if (this.invitationFilter === 'accepted') {
        list = list.filter(program => program.users_associationtables[0].status === 'Accept')
      } else if (this.invitationFilter === 'rejected') {
        list = list.filter(program => program.users_associationtables[0].status === 'Reject')
      }
      return list
    },
    invitationType() {
      if (this.tab === 'events') {
        return 'is_event: {_eq: true}'
      }
      if (this.tab === 'competitions') {
        return 'is_competition: {_eq: true}'
      }
      return 'is_program: {_eq: true}'
    },
  },
  methods: {
    showProgramDetails(program) {
      this.modalDetails = program
      this.$bvModal.show('view-details-modal')
    },
    showRespondModal(id, index) {
      this.selectedIndex = index
      this.$bvModal.show('respond-modal')
    },
    beginDate(date) {
      const dateNew = new Date(date)
      const options = { day: 'numeric', month: 'long', year: 'numeric' }
      const formattedDate = dateNew.toLocaleDateString('en-US', options)
      return formattedDate
    },
    updateResponse(status) {
      this.mutationLoading = true
      const stmt = [`
            update_users_associationtable_by_pk(pk_columns: {id: ${this.programs[this.selectedIndex].users_associationtables[0].id}}, _set: {status: "${status ? 'Accept' : 'Reject'}"}) {
              id
              status
            }`]
      // Update mentor table if accepted
      if (status === true) {
        stmt.push(`
            insert_programs_partnerstable_one(object: {user_id: ${this.userID}, program_id: ${this.programs[this.selectedIndex].id}, designation: ${this.programs[this.selectedIndex].users_associationtables[0].designation}, is_jury: ${this.programs[this.selectedIndex].users_associationtables[0].is_jury} }) {
              id
            }`)
      }
      this.$apollo.mutate({
        mutation: gql`mutation {${stmt.join('')}}`,
        update: (store, { data: { update_users_associationtable_by_pk } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: update_users_associationtable_by_pk.id ? 'Your response has been recorded' : 'Failed to submit response',
              icon: update_users_associationtable_by_pk.id ? 'CheckIcon' : 'XIcon',
              variant: update_users_associationtable_by_pk.id ? 'success' : 'danger',
            },
          })
          this.$apollo.queries.programs.refetch()
          window.location.reload()
          this.mutationLoading = false
        },
      })
    },
  },
  apollo: {
    invitations: {
      query() {
        const subdomain = this.getIncubatorFromSubdomain() || '.*'
        return gql`
        {
            programs_basicinfo(where: {${this.invitationType},users_organizationtable: {domain: {_regex: "${subdomain}"}}, users_associationtables: {user_id: {_eq: ${this.userID} }}}) {
              begin_date
              capacity
              id
              industry
              description
              type
              delivery_mode
              title
              status
              users_organizationtable {
                title
                url
              }
              users_associationtables(where: {user_id: {_eq: ${this.userID} }}) {
                id
                designation
                status
                role
              }
            }
          }`
      },
      update: data => {
        data.programs_basicinfo.forEach(program => {
          // eslint-disable-next-line no-unused-expressions,no-param-reassign
          program.users_associationtables[0].readonly = !(program.users_associationtables[0].status.toLowerCase() === 'invited')
        })
        return data.programs_basicinfo
      },
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
